/* Überschrift */

.mco-view-component-container-basic-headline h1 {
	color: var(--mco-palette-primary-main) !important;
	font-weight: 900 !important;
}
.mco-view-component-container-basic-headline h2 {
	padding-bottom: 16px !important;
	position: relative !important;
}
.mco-view-component-container-basic-headline h2::after {
	background: var(--mco-palette-primary-main);
	bottom: 0;
	content: "";
	display: block;
	height: 2px;
	left: 0;
	position: absolute;
	width: 50px;
}
.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-headline
	h2 {
	font-size: clamp(24px, 4vw, 32px);
}

/* Text */

.mco-text-01 a {
	color: #21262d;
	padding: 2px;
	position: relative;
	transition: background 300ms ease, background-size 300ms ease, color 300ms ease;
}
.mco-text-01 a::before {
	background: var(--mco-palette-primary-main);
	bottom: 0;
	content: "";
	height: 100%;
	left: 0;
	max-height: 2px;
	position: absolute;
	right: 0;
	transition: max-height 500ms ease;
	z-index: -1;
}
.mco-text-01 a:hover {
	color: #ffffff !important;
}
.mco-text-01 a:hover::before {
	max-height: 100%;
}
.mco-text-01 button {
	display: block !important;
	font-size: 16px !important;
	margin: 32px auto 0 !important;
}

/* Formular */

.mco-form .mco-form-headline {
	margin-bottom: 8px !important;
}
.mco-form .mco-form-headline:not(:first-of-type) {
	margin-top: 24px;
}
.mco-form .mco-form-button-wrapper {
	display: flex;
	justify-content: flex-end;
}

/* Impressum */

.mco-legacy a {
	color: #21262d;
	padding: 2px;
	position: relative;
	transition: background 300ms ease, background-size 300ms ease, color 300ms ease;
}
.mco-legacy a::before {
	background: var(--mco-palette-primary-main);
	bottom: 0;
	content: "";
	height: 100%;
	left: 0;
	max-height: 2px;
	position: absolute;
	right: 0;
	transition: max-height 500ms ease;
	z-index: -1;
}
.mco-legacy a:hover {
	color: #ffffff !important;
}
.mco-legacy a:hover::before {
	max-height: 100%;
}
.mco-legacy .mco-legacy-company-logo {
	max-width: 300px !important;
}

/* Suche */

.mco-search-results__item {
	background: #ffffff !important;
	box-shadow: 0 16px 24px 0 rgb(0 30 60 / 12%) !important;
}
.mco-search-results__item-title {
	color: var(--mco-palette-primary-main) !important;
}
.mco-search-results__item-title:hover {
	color: var(--mco-palette-primary-dark) !important;
}

/* Shop */

.mco-shop-paper {
	z-index: 1300 !important;
}
.mco-shop-select > div > div > svg {
	height: auto !important;
	max-height: 40px !important;
	max-width: 60px !important;
	width: auto !important;
}

/* Shop - Überschrift */

.mco-shop-headline__counter {
	font-weight: 900 !important;
}

/* Shop - Filter */

.mco-filter-item__label {
	color: var(--mco-palette-text-primary) !important;
}
.mco-filter-item__option:nth-child(5),
.mco-filter-item__option:nth-child(6) {
	display: none !important;
}
.mco-filter-item__button {
	background: #ffffff !important;
	border: none !important;
	box-shadow: 0 2px 8px 2px rgba(0, 30, 60, 0.12) !important;
	padding-bottom: 16px !important;
	padding-top: 16px !important;
}
.mco-filter-item__button:hover {
	background: #ffffff !important;
	box-shadow: 0 2px 8px 2px rgba(0, 30, 60, 0.15) !important;
}
.mco-filter-item__button--selected {
	background: var(--mco-palette-primary-main) !important;
	color: #ffffff !important;
}
.mco-filter-item__button--selected .mco-filter-item__label {
	color: #ffffff !important;
}
.mco-filter-item__button--selected .mco-filter-item__counter {
	border-color: #ffffff !important;
}
.mco-filter-item__button--selected .mco-filter-item__counter span {
	color: #ffffff !important;
}

/* Shop - Merkliste */

.mco-shop-bookmark__list {
	gap: 24px !important;
	grid-template-columns: 1fr !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
}

/* Shop - Produkt - Übersicht */

.mco-shop-product-overview {
	padding-left: 24px !important;
	padding-right: 24px !important;
}
.mco-shop-product-overview-list {
	gap: 24px !important;
	grid-template-columns: 1fr !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
}

/* Shop - Tags */

.mco-shop-tags .mco-shop-tags__tag {
	border-radius: 4px;
}
.mco-shop-tags .mco-shop-tags__tag--primary {
	color: #ffffff !important;
}
.mco-shop-tags .mco-shop-tags__tag--default {
	background: var(--mco-palette-gray-100) !important;
}

/* Shop - Warenkorb */

.mco-shop-cart-item__image {
	aspect-ratio: 1 / 1 !important;
}
.mco-shop-cart-item__image img {
	object-fit: contain !important;
}

/* Overlay */

.mco-overlay-toolbar {
	background: var(--mco-palette-primary-main) !important;
}
.mco-overlay .mco-overlay-scroll-images {
	width: 100% !important;
}
.mco-overlay .mco-overlay-scroll--native-fullscreen img {
	height: 100vh !important;
	width: 100vw !important;
}

/* Blog */

.mco-blog-overview .mco-blog-list-teaser-item--grid {
	box-shadow: 0px 0px 1px rgb(0 0 0 / 12%), 5px 16px 36px rgb(0 0 0 / 8%);
}
.mco-blog-overview .mco-blog-list-teaser-item__title {
	color: var(--mco-palette-primary-main) !important;
}
.mco-blog-overview .mco-blog-list-teaser-item__info-date {
	background: var(--mco-palette-background-light) !important;
	color: var(--mco-palette-text-primary) !important;
}
.mco-blog-overview .mco-blog-list-teaser-item__info-label {
	background: var(--mco-palette-primary-main) !important;
}
.mco-blog-overview .mco-blog-list-teaser-item__categories {
	color: var(--mco-palette-text-primary) !important;
}
.mco-blog-overview .mco-blog-list-teaser-item__author {
	color: var(--mco-palette-primary-main) !important;
}

/* Blog - Info */

.mco-blog-blogger-info {
	display: flex !important;
	flex-direction: column !important;
	gap: 12px !important;
}
.mco-blog-blogger-info .mco-blog-blogger-info__author-wrapper {
	justify-content: center !important;
}
.mco-blog-blogger-info .mco-blog-blogger-info__author {
	color: var(--mco-palette-primary-main) !important;
	text-decoration: underline !important;
}
.mco-blog-blogger-info .mco-blog-blogger-info__date {
	text-align: center !important;
}
.mco-view-component-container-mco-blog-detail-info .mco-share {
	display: flex !important;
	justify-content: flex-end !important;
}

/* Blog - Tags */

.mco-blog-tags .mco-blog-tags__headline {
	color: var(--mco-palette-text-secondary) !important;
}

/* Blog - Share */

.mco-view-component-container-mco-blog-share .mco-share {
	border-top: 1px solid #e6e5e5;
	display: flex;
	justify-content: center;
	padding-top: 32px;
}

/* Blog - Teaser */

.mco-view-component-container-mco-blog-teaser {
	padding-bottom: 80px !important;
	padding-top: 80px !important;
	position: relative;
}
.mco-view-component-container-mco-blog-teaser::before {
	background: #f7f7f8;
	bottom: 0;
	content: "";
	left: -50%;
	position: absolute;
	right: 0;
	top: 0;
	width: 5000px;
	z-index: -1;
}
.mco-blog-teaser {
	position: relative;
}
.mco-blog-teaser h2 {
	padding-bottom: 16px;
	position: relative;
}
.mco-blog-teaser h2::after {
	background: linear-gradient(270deg, #c65470 0%, #548eb5 100%);
	bottom: 0;
	content: "";
	height: 2px;
	left: 0;
	position: absolute;
	width: 56px;
}
.mco-blog-teaser .mco-blog-teaser-item {
	box-shadow: none !important;
	border-radius: 4px;
	/* transition: box-shadow 250ms ease; */
}
.mco-blog-teaser .mco-blog-teaser-item__title {
	color: var(--mco-palette-primary-main) !important;
}
.mco-blog-teaser .mco-blog-teaser-item__text {
	margin-top: 0 !important;
}
.mco-blog-teaser .mco-blog-teaser-item__info-date {
	background: var(--mco-palette-background-light) !important;
	color: var(--mco-palette-text-primary) !important;
}
.mco-blog-teaser .mco-blog-teaser-item__info-label {
	background: var(--mco-palette-primary-main) !important;
}
.mco-blog-teaser .mco-blog-teaser-item__categories {
	color: var(--mco-palette-text-primary) !important;
}
.mco-blog-teaser .mco-blog-teaser-item__author {
	color: var(--mco-palette-primary-main) !important;
}

/* Trusted Shops - Badge */

[id^="trustbadge-container"] {
	z-index: 1300 !important;
}

@media (max-width: 599px) {
	/* Formular */

	.mco-form-select {
		flex: 0 0 100% !important;
	}
}

@media (min-width: 600px) {
	/* Shop - Merkliste */

	.mco-shop-bookmark__list {
		grid-template-columns: 1fr 1fr !important;
	}

	/* Shop - Produkt - Übersicht */

	.mco-shop-product-overview-list {
		grid-template-columns: 1fr 1fr !important;
	}

	/* Blog - Teaser */

	.mco-blog-teaser h2 {
		padding-right: 150px;
	}
	.mco-blog-teaser .mco-blog-teaser-item {
		box-shadow: 0px 0px 1px rgb(0 0 0 / 12%), 8px 16px 32px rgb(0 0 0 / 8%);
	}
	.mco-blog-teaser .mco-blog-teaser-item:hover {
		box-shadow: 0px 0px 1px rgb(0 0 0 / 12%), 8px 16px 32px rgb(0 0 0 / 20%);
	}
	.mco-blog-teaser .mco-blog-teaser__button {
		position: absolute;
		margin: 0;
		right: 0;
		top: 0;
	}
}

@media (min-width: 960px) {
	/* Shop - Login */

	.mco-shop-login-form {
		border: none !important;
		box-shadow: 0 16px 32px 16px rgba(0, 30, 60, 0.12) !important;
	}

	/* Shop - Registrierung */

	.mco-shop-register {
		background: none !important;
		box-shadow: 0 16px 32px 16px rgba(0, 30, 60, 0.12) !important;
	}

	/* Shop - Merkliste */

	.mco-shop-bookmark__list {
		grid-template-columns: 1fr 1fr 1fr !important;
	}
}

@media (min-width: 1280px) {
	/* Shop - Merkliste */

	.mco-shop-bookmark__list {
		grid-template-columns: 1fr 1fr 1fr 1fr !important;
	}

	/* Shop - Produkt - Übersicht */

	.mco-shop-product-overview-list {
		gap: 24px !important;
		grid-template-columns: 1fr 1fr 1fr !important;
	}
}

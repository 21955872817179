@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 100;
	src: local(""), url("../fonts/montserrat-v25-latin-100.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-100.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 200;
	src: local(""), url("../fonts/montserrat-v25-latin-200.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-200.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	src: local(""), url("../fonts/montserrat-v25-latin-300.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-300.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/montserrat-v25-latin-regular.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-regular.woff");
}
@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	src: local(""), url("../fonts/montserrat-v25-latin-500.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-500.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	src: local(""), url("../fonts/montserrat-v25-latin-600.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-600.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("../fonts/montserrat-v25-latin-700.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-700.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 800;
	src: local(""), url("../fonts/montserrat-v25-latin-800.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-800.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 900;
	src: local(""), url("../fonts/montserrat-v25-latin-900.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-900.woff") format("woff");
}

/* Überschrift */

.mco-view-component-container-basic-headline + .mco-view-component-container-basic-headline {
	margin-top: -60px;
}

/* Überschrift & Text */

.mco-view-component-container-basic-headline + .mco-view-component-container-basic-text {
	margin-top: -32px;
}

/* Bild-Text Kombination */

.mco-view-component-container-basic-imagetext + .mco-view-component-container-basic-imagetext {
	margin-top: -64px;
}

/* Bild-Text Kombination & Einzelteaser */

.mco-view-component-container-basic-imagetext
	+ .mco-view-component-container-basic-singleteaser {
	margin-top: -64px;
}

/* Einzelteaser*/

.mco-view-component-container-basic-singleteaser
	+ .mco-view-component-container-basic-singleteaser {
	margin-top: -64px;
}

/* Dropdown */

.mco-view-component-container-basic-dropdown-group
	+ .mco-view-component-container-basic-dropdown-group {
	margin-top: -44px;
}

/* Download */

.mco-view-component-container-basic-download + .mco-view-component-container-basic-download {
	margin-top: -44px;
}

/* Hintergrund */

.mco-view-component-container--has-background:last-child {
	margin-bottom: 0 !important;
}
.mco-view-component-container--has-background + .mco-view-component-container--has-background {
	margin-top: -64px;
}
